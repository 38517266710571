import { Add } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { imageFormat, publicAxiosInstance } from 'Configs/axios'
import { API_URLS } from 'Configs/urls'
import register from 'assets/UPFSC.pdf'
import awards from 'assets/awards.jpeg'
import background from 'assets/background.jpg'
import member from 'assets/become a member.jpeg'
import history from 'assets/history.jpg'
import leaders from 'assets/leaders.png'
import panchayatiRaj from 'assets/Uttar-Pradesh-Panchayati-Raj-Department.jpg'
import seminar from 'assets/seminars-conference.jpeg'
import team from 'assets/team.pdf'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import ContactInfo from './contactinfo'
import News from './news'
import OurHistory from './ourhistory'
import profile from 'Pages/Team/images/pic7.jpeg'
import bgConstruction from 'assets/background-construcution.webp'

const Home = () => {
  const sliderRef = useRef(null)
  const [data, setData] = useState([]);
  const [galleryHeading, setGalleryHeading] = useState([])
  const [eventData, setEventData] = useState([]);
  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const sliderFn = async () => {
    try {
      const response = await publicAxiosInstance.get('/blog/slider-api/');
      setData(response?.data?.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const eventFn = async () => {
    try {
      const response = await publicAxiosInstance.get(API_URLS.event_api);
      setEventData(response?.data?.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };


  useEffect(() => {
    sliderFn();
    eventFn();
    // localStorage.setItem("user_type", "Engineer")
    // localStorage.setItem("sewaup", "adfasdfasdfasdfasdfasdfasdf")
  }, []);



  const galleryFn = async () => {
    try {
      const response = await publicAxiosInstance.get('/blog/gallery-image-api/')
      setGalleryHeading(response?.data?.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    galleryFn()
  }, [])

  const about = [
    {
      id: 1,
      image: history,
      title: 'Establishment',
      download: register
    },
    {
      id: 2,
      image: panchayatiRaj,
      title: 'Panchayati Raj',
    },
    {
      id: 3,
      image: leaders,
      title: 'Our Team',
      // download: team
      link: "/team"
    },
    {
      id: 4,
      image: seminar,
      link: "/newsevent",
      title: 'Seminars & Conferences ',
    },
    {
      id: 5,
      image: member,
      title: 'Become a member',
      link: "/login"
    },
    {
      id: 6,
      image: awards,
      title: 'Awards',
      link: "/about-us"
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  }

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  return (
    <div className="overflow-hidden text-black bg-white lg:p-0">
      <div className="!relative z-30">
        <Slider {...settings} className='!z-20'>
          {data?.map((item) => {
            return (
              <div key={item.id} className="!z-20">
                <img src={imageFormat(item.image)} alt={item.Title} className=" md:h-[40vh] lg:h-[78vh] w-[100vw] h-[30vh]" />
                <div className="mt-2 text-center">
                  <h3 className="text-lg font-bold text-black">{item.Title}</h3>
                  <p className="text-sm text-gray-600">{item.add}</p>
                </div>
              </div>
            )
          })}
        </Slider>
        <div className="absolute inset-0 flex items-center justify-between px-4">
          <button
            className="z-10 flex items-center justify-center w-16 h-16 text-white bg-gray-800 bg-opacity-30"
            onClick={handlePrevClick}
          >
            ❮
          </button>
          <button
            className="z-10 flex items-center justify-center w-16 h-16 text-white bg-gray-800 bg-opacity-30"
            onClick={handleNextClick}
          >
            ❯
          </button>
        </div>
      </div>
      <div className="relative flex flex-col items-center justify-center ">
        <img src={background} alt="" className='w-full lg:h-[900px] md:h-[1200px] h-[1350px] object-cover ' />
        <div className="absolute z-20 p-4 mb-10 md:p-0 lg:z-20 top-10">
          <p className="text-2xl font-bold text-center md:text-4xl">Welcome to Consulting Engineer & Architect Welfare Association</p>
          <p className="    text-[#777777] p-4 text-lg text-center">
            Read the News Updates and Articles about Consulting Engineer & Architect Welfare Association
          </p>
          <div className="flex lg:flex-row flex-col gap-10 w-[100%] md:w-[80%] mx-auto">
            <div className="grid grid-cols-2 gap-10 shadow lg:grid-cols-3 ">
              {about.map((detail) => (
                <div
                  className="bg-white  justify-center items-center shadow gap-3 flex flex-col h-[100%] border-2 border-gray-300 p-4 rounded-xl"
                  key={detail.id}
                >
                  <div className='w-24 h-24 overflow-hidden rounded-full'>
                    <img src={detail.image} alt="" className="object-cover object-top w-full h-full" /></div>
                  <p className="lg:w-[80%] text-center text-xl font-bold">{detail.title}</p>
                  {!detail.link && !detail.download && <button className="text-white bg-[#d94148] p-1 w-[80%] text-center rounded-xl text-sm">
                    Read More
                  </button>}
                  {detail.link && <Link to={detail.link} className="text-white bg-[#d94148] p-1 w-[80%] text-center rounded-xl text-sm">
                    Read More
                  </Link>}
                  {detail.download &&
                    <a
                      href={detail.download}
                      className="text-white bg-[#d94148] p-1 w-[80%] text-center rounded-xl text-sm"
                      download
                    >
                      Read More
                    </a>
                  }
                </div>
              ))}
            </div>
            <div className=" lg:w-[30%] w-[90%] flex flex-col  gap-2 md:px-0 px-4">
              <div className="space-y-3 accordion-container">
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className=' !rounded-xl'>
                  <AccordionSummary
                    expandIcon={<Add />}
                    className="!text-base !bg-blue-950 text-white !rounded-xl "
                  >
                    <Typography className="!text-base text-white !font-bold px-2">
                      MEETINGS
                    </Typography>
                  </AccordionSummary>
                  {eventData.map(g =>
                    <AccordionDetails className="accordion-details !bg-blue-950">
                      <Link to={`/event/${g.id}`}>
                        {g?.title}
                      </Link>
                    </AccordionDetails>
                  )}
                  <AccordionDetails className="accordion-details !bg-blue-950">
                    <Link to={`/newsevent`}>
                      View All Protest Meetings
                    </Link>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className=' !rounded-xl'>
                  <AccordionSummary
                    expandIcon={<Add />}
                    className="!text-base !bg-blue-950 text-white !rounded-xl "
                  >
                    <Typography className="!text-base bg-blue-950 text-white !font-bold px-2">
                      Gallery
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details !bg-blue-950" >
                    {galleryHeading.map(g =>
                      <AccordionDetails className="accordion-details !bg-blue-950">
                        <Link to="/view-gallery">
                          {g?.title}
                        </Link>
                      </AccordionDetails>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <News />
      <div className="relative overflow-hidden">
        <img src={bgConstruction} alt="background" className="absolute w-full h-full object-cover top-0 left-0 z-10 blur-[3px]" />
        <div className="absolute inset-0 z-10 opacity-75 bg-gradient-to-r from-purple-800 via-pink-600 to-blue-600 mix-blend-multiply"></div>

        <div className="relative lg:w-[70%] w-[100%] px-[10%] md:px-0 mx-auto py-20 z-20">
          <div className="grid items-center grid-cols-1 gap-8 md:grid-cols-3">
            <div className="rounded-lg overflow-hidden lg:h-[500px] h-[350px] relative transform hover:scale-105 transition-transform duration-300 shadow-lg">
              <img src="https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic222.jpeg" alt="Profile" className="object-cover object-top w-full h-full transition-all duration-300 filter grayscale hover:grayscale-0" />
              <div className="absolute bottom-0 left-0 z-10 w-full py-2 text-center bg-white bg-opacity-80 backdrop-blur-sm">
                <p className="text-lg font-bold">जीशान सिद्दीकी</p>
                <p className="text-sm italic">महामंत्री</p>
              </div>
            </div>
            <div className="rounded-lg overflow-hidden lg:h-[500px] h-[350px] relative transform hover:scale-105 transition-transform duration-300 shadow-lg">
              <img src="https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic14.jpeg" alt="Profile" className="object-cover object-top w-full h-full transition-all duration-300 filter grayscale hover:grayscale-0" />
              <div className="absolute bottom-0 left-0 z-10 w-full py-2 text-center bg-white bg-opacity-80 backdrop-blur-sm">
                <p className="text-lg font-bold">सुनील दुबे</p>
                <p className="text-sm italic">अध्यक्ष</p>
              </div>
            </div>
            <div className="rounded-lg overflow-hidden lg:h-[500px] h-[350px] relative transform hover:scale-105 transition-transform duration-300 shadow-lg">
              <img src="https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic111.jpeg" alt="Profile" className="object-cover object-top w-full h-full transition-all duration-300 filter grayscale hover:grayscale-0" />
              <div className="absolute bottom-0 left-0 z-10 w-full py-2 text-center bg-white bg-opacity-80 backdrop-blur-sm">
                <p className="text-lg font-bold">शिवसागर यादव</p>
                <p className="text-sm italic">कोषाध्यक्ष</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactInfo />
      <OurHistory />
    </div>
  )
}

export default Home
