import AdminHeader from "Components/Admin/common/AdminHeader";
import Sidebar from "Components/Admin/common/Sidebar";
import useAuth from "hooks/useAuth";

import { Outlet } from "react-router-dom";

const AdminLayout = () => {


    const { userType } = useAuth()
    return (
        <div className="grid lg:grid-cols-[300px_1fr] h-screen w-screen overflow-hidden">
            <Sidebar hidden={true}  />
            <div className="flex flex-col w-full gap-2 p-2 overflow-x-auto bg-gray-100">
                <AdminHeader />
                <div className="flex flex-col overflow-y-auto text-black bg-white rounded-lg">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
