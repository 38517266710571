import { publicAxiosInstance } from "Configs/axios";
import { API_URLS } from "Configs/urls";

export const DesignationPhotoFn = async (reqBody) => {
    try {
        const response = publicAxiosInstance.get(API_URLS.engineers_with_designation);
        return response;
    } catch (error) {
        throw error;
    }
}