/* eslint-disable jsx-a11y/img-redundant-alt */
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import Sidebar from 'Components/Admin/common/Sidebar';
import Plans from 'Components/Common/Plans';
import { imageFormat } from 'Configs/axios';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ProfileSchema } from 'Schema/Profile';
import { getCertificatePlanFn, getCityFn, getCountryFn, getProfileFn, getStateFn, postProfileFn } from 'Services/Admin/Profile';
import MenuIcon from '@mui/icons-material/Menu';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const Profile = () => {
    const [country, setCountry] = useState([]);
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState(null);
    const [countryId, setCountryId] = useState(null);
    const [cities, setCities] = useState([]);
    const [imageSrc, setImageSrc] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isDesignationEnabled, setIsDesignationEnabled] = useState(false);

    const { data: getProfileResponse, refetch: refetchProfile } = useQuery({
        queryFn: getProfileFn,
        queryKey: ["get-profile"]
    });

    useQuery({
        queryFn: getCountryFn,
        queryKey: ["get-countries"],
        onSuccess: (response) => {
            const countries = response.data?.data.map((country) => ({ label: country.country, id: country.id }));
            setCountry(countries);
        },
        onError: (error) => {
            console.error('Error fetching countries:', error);
        }
    });

    const { data: getCertificatePlanResponse } = useQuery({
        queryFn: getCertificatePlanFn,
        queryKey: ['get-certificate-plan']
    })
    const getCertificatePlan = getCertificatePlanResponse?.data?.data || [];

    useQuery({
        queryFn: () => getStateFn(countryId),
        queryKey: ["get-state", countryId],
        enabled: !!countryId,
        onSuccess: (response) => {
            const s = response.data?.data.map((state) => ({ label: state.state, id: state.id }));
            setStates(s);
        },
        onError: (error) => {
            console.error('Error fetching states:', error);
        }
    });

    useQuery({
        queryFn: () => getCityFn(stateId),
        queryKey: ["get-city", stateId],
        enabled: !!stateId,
        onSuccess: (response) => {
            const c = response.data?.data.map((city) => ({ label: city.city, id: city.id }));
            setCities(c);
        },
        onError: (error) => {
            console.error('Error fetching cities:', error);
        }
    });

    const { mutate: postProfile } = useMutation(postProfileFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                refetchProfile();
                toast.success("Updated Successfully.");
            } else {
                toast.error(response?.data?.message);
            }
        },
        onError: (error) => {
            console.error('Error updating profile:', error);
            toast.error("Something went wrong.");
        }
    });

    const profileData = getProfileResponse?.data?.data;
    const initialValues = {
        id: profileData?.id || "",
        prefix: profileData?.prefix || "",
        name: profileData?.name || "",
        about: profileData?.about || "",
        dob: profileData?.dob || "",
        gender: profileData?.gender || "",
        fathername: profileData?.fathername || "",
        address: profileData?.address || "",
        country: profileData?.country || "India",
        state: profileData?.state || "Uttar Pradesh",
        district: profileData?.district || "",
        block: profileData?.block || "",
        city: profileData?.city || "",
        mobile: profileData?.mobile || "",
        email: profileData?.email || "",
        image: profileData?.image || "",
        designation:isDesignationEnabled ?( profileData?.designation || "Other"):"",
      


        member_id: profileData?.member_id || ""
    };

    const { values, handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched } = useFormik({
        initialValues,
        validationSchema: ProfileSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]));

            if (!values.image) return toast.error("Image is required.");

            if (typeof values.image === "string") {
                formData.delete("image");
            }

            postProfile(formData);
        }
    });

    const toggleButton =
        (!!profileData?.id &&
            !!profileData?.prefix &&
            !!profileData?.name &&
            !!profileData?.about &&
            !!profileData?.dob &&
            !!profileData?.gender &&
            !!profileData?.fathername &&
            !!profileData?.address &&
            !!profileData?.country &&
            !!profileData?.state &&
            !!profileData?.district &&
            !!profileData?.city &&
            !!profileData?.block &&
            !!profileData?.mobile &&
            !!profileData?.email &&
            !!profileData?.image &&
            !!profileData?.designation &&
            !!profileData?.member_id
        ) && profileData?.approval_status !== "Rejected";

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFieldValue("image", file);
    };

    const handleDeleteImage = () => {
        setImageSrc(null);
        setFieldValue("image", "");
    };

    const setImageUrlFn = () => {
        if (typeof values.image === "string") {
            return imageFormat(values.image);
        } else if (values.image) {
            return URL.createObjectURL(values.image);
        }
        return null;
    };

    useEffect(() => {
        if (values.image) {
            setImageSrc(setImageUrlFn());
        }
    }, [values.image]);

    useEffect(() => {
        if (values.country) {
            const c = country.find(c => c.label === values.country);
            setCountryId(c?.id || null);
        }
    }, [values.country, country]);

    useEffect(() => {
        if (values.state) {
            const s = states.find(s => s.label === values.state);
            setStateId(s?.id || null);
        }
    }, [values.state, states]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDownloadCertificate = async () => {
        if (profileData?.certificate) {
            const url = imageFormat(profileData.certificate);
            const blob = await fetch(url).then(res => res.blob());
            saveAs(blob, profileData?.name + "-certificate.png");
        } else {
            toast.error("No certificate available to download.");
        }
    };
 // Handle the change of the Switch component
 const handleSwitchChange = (event) => {
    setIsDesignationEnabled(event.target.checked);
};
    const designations = [
        {
            id: 1,
            label: "Other",
            value: "Other",
        },
        {
            id: 2,
            label: "District/Mandal Post Holder",
            value: "District/Mandal Post Holder",
        },
        {
            id: 3,
            label: "District/Mandal President",
            value: "District/Mandal President",
        },
        {
            id: 4,
            label: "District/Mandal Vice President",
            value: "District/Mandal Vice President",
        },
        {
            id: 5,
            label: "District/Mandal Mahamantri",
            value: "District/Mandal Mahamantri",
        },
        {
            id: 6,
            label: "District/Mandal Koshadyaksh",
            value: "District/Mandal Koshadyaksh",
        },
        {
            id: 7,
            label: "District/Mandal Media Incharge",
            value: "District/Mandal Media Incharge",
        },
        {
            id: 8,
            label: "State/Mandal Post Holder",
            value: "State/Mandal Post Holder",
        },
        {
            id: 3,
            label: "State/Mandal President",
            value: "State/Mandal President",
        },
        {
            id: 4,
            label: "State/Mandal Vice President",
            value: "State/Mandal Vice President",
        },
        {
            id: 5,
            label: "State/Mandal Mahamantri",
            value: "State/Mandal Mahamantri",
        },
        {
            id: 6,
            label: "State/Mandal Koshadyaksh",
            value: "State/Mandal Koshadyaksh",
        },
        {
            id: 7,
            label: "State/Mandal Media Incharge",
            value: "State/Mandal Media Incharge",
        }
    ]

    const city = [
        {
            id: 1,
            label: "Aligarh",
            value: "Aligarh",
        },
        {
            id: 2,
            label: "Agra",
            value: "Agra",
        },
        {
            id: 3,
            label: "Lucknow",
            value: "Lucknow",
        },
        {
            id: 4,
            label: "Azamgarh",
            value: "Azamgarh",
        },
        {
            id: 5,
            label: "Kanpur",
            value: "Kanpur",
        },
        {
            id: 6,
            label: "Gorakhpur",
            value: "Gorakhpur",
        },
        {
            id: 7,
            label: "Chitrakoot",
            value: "Chitrakoot",
        },
        {
            id: 8,
            label: "Jhansi",
            value: "Jhansi",
        },
        {
            id: 9,
            label: "Devipatan",
            value: "Devipatan",
        },
        {
            id: 10,
            label: "Ayodhya",
            value: "Ayodhya",
        },
        {
            id: 11,
            label: "Basti",
            value: "Basti",
        },
        {
            id: 12,
            label: "Chitrakoot",
            value: "Chitrakoot",
        },
        {
            id: 13,
            label: "Gorakhpur",
            value: "Gorakhpur",
        },
        {
            id: 14,
            label: "Bareilly",
            value: "Bareilly",
        },
        {
            id: 15,
            label: "Vindhya",
            value: "Vindhya",
        }, {
            id: 16,
            label: "Muradabad",
            value: "Muradabad",
        },
        {
            id: 17,
            label: "Meerut",
            value: "Meerut",
        },
        {
            id: 18,
            label: "Prayagraj",
            value: "Prayagraj",
        },
        {
            id: 19,
            label: "Varanasi",
            value: "Varanasi",
        },
        {
            id: 20,
            label: "Saharanpur",
            value: "Saharanpur",
        }
    ]



    const validity = new Date(profileData?.certificate_validity);
    const currentDate = new Date();

    // Calculate the number of days until the certificate expires
    const msPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const daysUntilExpiration = Math.round((validity - currentDate) / msPerDay);

    // Check if the certificate is expired or will expire in the next 10 days
    const isExpired = daysUntilExpiration < 0;
    const isExpiringSoon = daysUntilExpiration >= 0 && daysUntilExpiration <= 10;

    // Determine button text and disabled state
    const getButtonText = () => {
        if (profileData?.buy_certificate?.length > 0) {
            if (profileData?.approval_status === "Pending") {
                return "Certificate in Review...";
            } else if (profileData?.approval_status === "Approved") {
                return isExpired
                    ? "Renew Certificate Again"
                    : isExpiringSoon
                        ? `Certificate will expire in ${daysUntilExpiration} day(s)`
                        : "Certificate Approved";
            }
        }
        return "Get Certificate";
    };

    const isButtonDisabled = () => {
        return profileData?.approval_status === "Pending" && profileData?.buy_certificate?.length > 0;
    };

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

    return (


        <div className='w-full h-full '>
              <div className='flex justify-between p-2'>
        

        <Button onClick={toggleDrawer(true)} className='!block lg:!hidden !px-0 '><MenuIcon className='!text-3xl'/></Button>
     
            {profileData?.member_id && <span className='flex items-center px-1 py-2 text-xs text-white bg-green-600 rounded-md lg:px-4 lg:text-lg'>Member Id: {profileData?.member_id}</span>}

            {toggleButton
                ? (

                    <Button
                    className='!lg:text-lg !text-xs'
                        variant="contained"
                        onClick={handleOpenDialog}
                        disabled={isButtonDisabled()}
                        sx={{
                            // position: "absolute",
                            // right: "2rem",
                            // top: "5.5rem",
                        }}
                    >
                        {getButtonText()}
                    </Button>
                ) : (
                    <Button
                     className='!lg:text-lg !text-xs'
                        variant="contained"
                        onClick={handleOpenDialog}
                        disabled={true}
                        sx={{
                            // position: "absolute",
                            // right: "2rem",
                            // top: "5.5rem",
                        }}
                    >
                        {profileData?.approval_status === "Rejected" && "You are rejected."}
                        {profileData?.approval_status !== "Rejected" && "Get Certificate (Update Profile)"}
                    </Button>
                )
            }
            </div>
       
        <div className='p-4 '>


            <p className="my-3 text-5xl text-center text-blue-900 underline">Profile</p>

          

            <div className="relative flex items-center w-32 h-32 m-4 mx-auto mt-10 text-xs text-center text-white bg-gray-300 border rounded-full">
                {imageSrc ? (
                    <div className='object-top w-full h-full overflow-hidden rounded-full'>
                        <img src={imageSrc} className="object-cover w-full h-full rounded-md" alt="profile image" />
                    </div>
                ) : (
                    <p className='mx-auto text-xl text-center'>Profile <br /> Image</p>
                )}
                <input
                    type="file"
                    name="image"
                    id="image"
                    className="hidden"
                    onChange={handleImageChange}
                />
                <label
                    className="absolute grid w-6 h-6 text-white bg-red-500 rounded-full cursor-pointer -bottom-2 -left-3 place-content-center hover:bg-red-400"
                    onClick={handleDeleteImage}
                >
                    <DeleteForeverIcon className="!text-[20px]" />
                </label>
                <label
                    htmlFor="image"
                    className="absolute grid w-6 h-6 text-white bg-orange-500 rounded-full cursor-pointer -bottom-2 -right-3 place-content-center hover:bg-green-400"
                >
                    <DriveFileRenameOutlineIcon className="!text-[20px]" />
                </label>
            </div>

            <form  class="mt-8 max-w-[1000px] grid  !grid-cols-3  gap-4 mx-auto" onSubmit={handleSubmit}>
                <FormControl fullWidth error={touched.prefix && Boolean(errors.prefix)}>
                    <InputLabel id="prefix-label">I am</InputLabel>
                    <Select
                        labelId="prefix-label"
                        id="prefix"
                        name="prefix"
                        value={values.prefix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="I am"
                    >
                        <MenuItem value="ER">Engineer</MenuItem>
                        <MenuItem value="AR">Architect</MenuItem>
                    </Select>
                    {touched.prefix && errors.prefix && (
                        <FormHelperText>{errors.prefix}</FormHelperText>
                    )}
                </FormControl>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
              
                <TextField
                    id="about"
                    name="about"
                    label="About"
                    value={values.about}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.about && Boolean(errors.about)}
                    helperText={touched.about && errors.about}
                />
                <TextField
                    id="dob"
                    name="dob"
                    type="date"
                    label="Date of Birth"
                    value={values.dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={touched.dob && Boolean(errors.dob)}
                    helperText={touched.dob && errors.dob}
                />
                <FormControl fullWidth error={touched.gender && Boolean(errors.gender)}>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                        labelId="gender-label"
                        id="gender"
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Gender"
                    >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    {touched.gender && errors.gender && (
                        <FormHelperText>{errors.gender}</FormHelperText>
                    )}
                </FormControl>
                <TextField
                    id="fathername"
                    name="fathername"
                    label="Father's Name"
                    value={values.fathername}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.fathername && Boolean(errors.fathername)}
                    helperText={touched.fathername && errors.fathername}
                />
                <TextField
                    id="address"
                    name="address"
                    label="Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                />
                <Autocomplete
                    id="country"
                    name="country"
                    options={country}
                    disabled
                    value={values.country || ""}
                    onChange={(_e, newValue) => setFieldValue("country", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Country"
                            error={touched.country && Boolean(errors.country)}
                            helperText={touched.country && errors.country}
                        />
                    )}
                    onBlur={handleBlur}
                />
                <Autocomplete
                    id="state"
                    name="state"
                    options={states}
                    // disabled
                    value={values.state || ""}
                    onChange={(_e, newValue) => setFieldValue("state", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="State"
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}
                        />
                    )}
                    onBlur={handleBlur}
                />
                  {/* <Autocomplete
                    id="designation"
                    name="designation"
                    options={designations}
                    value={values.designation || ""}
                    onChange={(_e, newValue) => setFieldValue("designation", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Designation"
                            error={touched.designation && Boolean(errors.designation)}
                            helperText={touched.designation && errors.designation}
                        />
                    )}
                    onBlur={handleBlur}
                /> */}




                <div>
            {/* Switch to enable/disable the designation field */}
            <FormControlLabel

             className='!text-xs'
                control={
                    <Switch
                        checked={isDesignationEnabled}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                }
                label="Do you Hold Any Designation ?"
            />

            {/* Conditionally render the Autocomplete field based on switch */}
           </div>

           {isDesignationEnabled && (
                <Autocomplete
                    id="designation"
                    name="designation"
                    options={designations}
                    value={values.designation || ""}
                    onChange={(_e, newValue) => setFieldValue("designation", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Designation"
                            error={touched.designation && Boolean(errors.designation)}
                            helperText={touched.designation && errors.designation}
                        />
                    )}
                    onBlur={handleBlur}
                />
            )}
                <Autocomplete
                    id="district"
                    name="district"
                    options={cities}
                    value={values.district || ""}
                    onChange={(_e, newValue) => setFieldValue("district", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="District"
                            error={touched.district && Boolean(errors.district)}
                            helperText={touched.district && errors.district}
                        />
                    )}
                    onBlur={handleBlur}
                />

                <TextField
                    id="block"
                    name="block"
                    label="Block"
                    value={values.block}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.block && Boolean(errors.block)}
                    helperText={touched.block && errors.block}
                />
                <Autocomplete
                    id="city"
                    name="city"
                    options={city}
                    value={values.city || ""}
                    onChange={(_e, newValue) => setFieldValue("city", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Mandal"
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}
                        />
                    )}
                    onBlur={handleBlur}
                />
                <TextField
                    id="mobile"
                    name="mobile"
                    label="Mobile Number"
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                />
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth

                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                />
                {/* <TextField
                    id="member_id"
                    name="member_id"
                    label="Member ID"
                    value={values.member_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled
                    error={touched.member_id && Boolean(errors.member_id)}
                    helperText={touched.member_id && errors.member_id}
                /> */}
                <div className='col-span-3'>
                    <Button variant="contained" color="primary" type="submit">Submit</Button>
                </div>
            </form>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    {profileData?.buy_certificate?.length > 0 && profileData?.approval_status === "Approved"
                        ? getButtonText() === "Renew Certificate Again" && !profileData?.certificate
                            ? "Renew Again"
                            : "Your Certificate"
                        : "Get Certificate"
                    }
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {(profileData?.buy_certificate?.length > 0 && profileData?.approval_status === "Approved")
                    ? getButtonText() === "Renew Certificate Again" && !profileData?.certificate
                        ? <Plans plans={getCertificatePlan} handleCloseDialog={handleCloseDialog} refetch={refetchProfile} />
                        : <>
                            <DialogContent>
                                <img src={imageFormat(profileData?.certificate)} alt="certificate" className='object-contain w-full h-full' />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog}>Close</Button>
                                <Button onClick={handleDownloadCertificate}>Download</Button>
                            </DialogActions>
                        </>
                    : <Plans plans={getCertificatePlan} handleCloseDialog={handleCloseDialog} refetch={refetchProfile} />
                }
            </Dialog>
            <Drawer open={open} onClose={toggleDrawer(false)}  PaperProps={{
            sx: { width: "60%" },
          }}>
       <Sidebar />
      </Drawer>
        </div>
        </div>
    );
};


export default Profile;
