import React, { useEffect, useState } from 'react';
import useAuth from "hooks/useAuth";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { activateCertificatePlanFn, buyCertificatePlanFn, deleteCertificatePlanFn } from "Services/Admin/Profile";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { protectedRoutes, RAZORPAY_KEY } from 'Configs/urls';
import logo from 'assets/logo-transparent.png';
import { privateAxiosIntance } from 'Configs/axios';
import PaymentSuccess from '../PaymentSuccess';

const Plans = ({ plans, refetch, handleCloseDialog }) => {
    const { userType } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [orderId, setOrderId] = useState(null);

    const handleDelete = (id) => {
        const formData = new FormData();
        formData.append("certificate_plan_id", id);
        deleteCertificatePlan(formData);
    };

    const handleStatusChange = (id) => {
        const formData = new FormData();
        formData.append("certificate_plan_id", id);
        activateCertificatePlan(formData);
    };

    const handlePaymentSuccess = async (response) => {
        setIsLoading(false);
        try {
            const bodyData = new FormData();
            bodyData.append("response", JSON.stringify(response));

            await privateAxiosIntance.post(protectedRoutes.buy_cerificate_payment_success, bodyData);
            refetch();
            setOrderId(response.razorpay_order_id);
            handleCloseDialog();
            toast.success("Payment successful!");
        } catch (error) {
            console.error(error);
            toast.error("Payment verification failed.");
        }
    };

    const showRazorpay = async (data) => {
        setIsLoading(true);

        const loadScript = () => new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => resolve(true);
            script.onerror = () => reject(new Error("Razorpay SDK failed to load"));
            document.body.appendChild(script);
        });

        const scriptLoaded = await loadScript();
        if (!scriptLoaded) {
            toast.error("Razorpay SDK failed to load.");
            setIsLoading(false);
            return;
        }

        const options = {
            key: RAZORPAY_KEY,
            amount: data?.total_payable_amount,
            currency: "INR",
            name: "CEAWAUP",
            description: "Test transaction",
            image: logo,
            order_id: data?.payment_no,
            handler: handlePaymentSuccess,
            prefill: {
                name: data?.name,
                email: data?.email,
                contact: data?.mobile,
            },
            theme: { color: "#3399cc" },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const { mutate: deleteCertificatePlan } = useMutation(deleteCertificatePlanFn, {
        onSuccess: () => {
            refetch();
            toast.success("Plan deleted successfully.");
        },
    });

    const { mutate: activateCertificatePlan } = useMutation(activateCertificatePlanFn, {
        onSuccess: () => {
            refetch();
            toast.success("Plan status updated successfully.");
        },
    });

    const { mutate: buyCertificatePlan } = useMutation(buyCertificatePlanFn, {
        onSuccess: (response) => {
            const data = response.data.data;
            showRazorpay(data);
        },
        onError: () => {
            toast.error("Failed to purchase plan.");
            setIsLoading(false);
        }
    });

    const becomeMember = (id) => {
        const formData = new FormData();
        formData.append('certificateplan_id', id);
        console.log(id);
        buyCertificatePlan(formData);
    };

    useEffect(() => {
        if (orderId) {
            setTimeout(() => setOrderId(null), 3000);
        }
    }, [orderId]);

    return (
        <div className="container p-4 mx-auto">
            {orderId && <PaymentSuccess orderId={orderId} />}
            <div className={`gap-6 grid ${userType === "Engineer" ? " grid-cols-1  max-w-[700px] md:grid-cols-2" : "grid-cols-3"} w-full mx-auto`}>
                {plans.map((plan) => (
                    <div
                        key={plan.id}
                        className={`border rounded-lg p-6 bg-white transition-transform duration-300 ${userType === "Engineer" ? 'hover:scale-105 shadow-lg' : ''} ${plan.is_activate ? 'border-blue-500' : 'border-gray-300'} max-w-sm`}
                    >
                        <div className="mb-6 text-center">
                            <h3 className={`text-2xl font-semibold mb-2 ${plan.is_activate ? 'text-blue-600' : 'text-gray-800'}`}>{plan.type} Membership</h3>
                            <p className={`text-lg font-semibold ${plan.is_activate ? 'text-blue-600' : 'text-gray-700'}`}>₹{plan.price}</p>
                        </div>
                        {userType === "Admin" && (
                            <>
                                <div className="flex items-center justify-between mb-4">
                                    <p className={`text-sm ${plan.is_activate ? 'text-green-600' : 'text-red-600'}`}>
                                        Status: {plan.is_activate ? 'Active' : 'Disabled'}
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={plan.is_activate}
                                                onChange={() => handleStatusChange(plan.id)}
                                            />
                                        }
                                        label={plan.is_activate ? 'Active' : 'Inactive'}
                                    />
                                </div>
                                <p className="mb-4 text-sm text-center text-gray-500">
                                    Created on: {new Date(plan.is_created).toLocaleDateString()}
                                </p>
                                <div className="flex items-center justify-between mt-4">
                                    <button
                                        className="px-4 py-2 text-white transition duration-300 bg-blue-600 rounded-lg hover:bg-blue-700"
                                        onClick={() => navigate(`/create-certificate-plan/update`, { state: plan.id })}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="px-4 py-2 text-white transition duration-300 bg-red-600 rounded-lg hover:bg-red-700"
                                        onClick={() => handleDelete(plan.id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </>
                        )}
                        {userType === "Engineer" && (
                            <button
                                className={`${plan.is_activate ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'} text-white py-3 px-6 rounded-lg transition duration-300 w-full`}
                                disabled={!plan.is_activate}
                                onClick={() => becomeMember(plan.id)}
                            >
                                {plan.is_activate ? 'Get Certificate' : 'Unavailable'}
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Plans;
