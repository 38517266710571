import CloseIcon from '@mui/icons-material/Close'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  getEngineersFn,
  postEngineerApprovalStatusFn,
  postEngineerStatusFn,
} from 'Services/Admin/Engineers'
import RemarkModal from './RemarkModal'
import CertificateGenerate from 'Components/Common/CertificateGenerate'
import { imageFormat } from 'Configs/axios'
import { Cancel, CheckCircle, Delete, Edit, Update } from '@mui/icons-material'
import { ProfileSchema } from 'Schema/Profile'
import {
  DeleteProfileFn,
  getEngineersByIdFn,
  UpdateProfileFn,
} from 'Services/Admin/Engineers/GetDataThroughId'
import { useFormik } from 'formik'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
}

const Engineers = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [totalPage, setTotalPage] = useState(1)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedCertificate, setSelectedCertificate] = useState(null)
  const [approved, setApproved] = useState(null)

  const { data: getEngineer, refetch } = useQuery({
    queryKey: ['get-engineers', page, search],
    queryFn: () => getEngineersFn({ search, page }),
    keepPreviousData: true,
  })

  const { mutate: postEngineerStatus } = useMutation(postEngineerStatusFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        refetch()
        toast.success('Status Updated')
      }
    },
  })

  const handleChange1 = (event, value) => {
    setPage(value)
  }

  const handleStatus = (id) => {
    const formData = new FormData()
    formData.append('engineer_id', id)
    postEngineerStatus(formData)
  }

  const handleCertificateClick = (certificate) => {
    setSelectedCertificate(certificate)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedCertificate(null)
  }

  useEffect(() => {
    if (getEngineer?.data) {
      setTotalPage(getEngineer?.data?.total_pages || 1)
    }
  }, [getEngineer])

  const [open, setOpen] = useState(false)
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false)

  // const initialValues = {
  //     id: profileData?.id || "",
  //     prefix: profileData?.prefix || "",
  //     name: profileData?.name || "",
  //     about: profileData?.about || "",
  //     dob: profileData?.dob || "",
  //     gender: profileData?.gender || "",
  //     fathername: profileData?.fathername || "",
  //     address: profileData?.address || "",
  //     country: profileData?.country || "India",
  //     state: profileData?.state || "Uttar Pradesh",
  //     district: profileData?.district || "",
  //     block: profileData?.block || "",
  //     city: profileData?.city || "",
  //     mobile: profileData?.mobile || "",
  //     email: profileData?.email || "",
  //     image: profileData?.image || "",
  //     designation:isDesignationEnabled ?( profileData?.designation || "Other"):"",

  //     member_id: profileData?.member_id || ""
  // };

  // const { values, handleSubmit, handleBlur, handleChange:changeHandle, setFieldValue, errors, touched } = useFormik({
  //     initialValues,
  //     validationSchema: ProfileSchema,
  //     enableReinitialize: true,
  //     onSubmit: (values) => {
  //         const formData = new FormData();
  //         Object.keys(values).forEach(key => formData.append(key, values[key]));

  //         if (!values.image) return toast.error("Image is required.");

  //         if (typeof values.image === "string") {
  //             formData.delete("image");
  //         }

  //         postProfile(formData);
  //     }
  // });

  return (
    <section className="p-4">
      <p className="my-3 text-5xl text-center text-blue-900 underline">Engineers</p>
      <div className="flex items-center my-2 mt-4">
        <span className="flex gap-3">
          <input
            className="block px-2 py-2 pr-3 mx-3 bg-white border rounded-md shadow-sm placeholder:italic placeholder:text-slate-400 w-80 border-slate-300 focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
            placeholder="Search for anything..."
            type="text"
            name="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </span>
        {getEngineer?.data?.total_engineer && (
          <span>Total Engineers & Architects: {getEngineer?.data?.total_engineer}</span>
        )}
      </div>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', maxHeight: 440, marginTop: '1rem' }}
        className="!text-black !mx-auto !bg-white overflow-auto px-2"
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Member ID
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Profile Photo
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                ER/AR
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Name
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Father Name
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Address
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Email
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Mobile
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                DOB
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Gender
              </TableCell>
              {/* <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Country
                            </TableCell> */}
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                State
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                District
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Block
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Status
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Approval Status
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Paid Status
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Created AT
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Certificate
              </TableCell>

              <TableCell
                style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getEngineer?.data?.data.map((row) => (
              <Row
                row={row}
                handleCertificateClick={handleCertificateClick}
                handleStatus={handleStatus}
                refetch={refetch}
                setApproved={setApproved}
                setOpen={setOpen}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center p-2">
        <Pagination
          page={page}
          size="small"
          count={totalPage}
          variant="outlined"
          color="primary"
          onChange={handleChange1}
        />
      </div>

      {/* Certificate Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          Certificate
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedCertificate ? (
            <img src={selectedCertificate} alt="Certificate" className="w-1/2 mx-auto border" />
          ) : (
            <p>No certificate available.</p>
          )}
        </DialogContent>
      </Dialog>
      <CertificateGenerate data={approved} setApproved={setApproved} refetch={refetch} />

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}></Box>
        </Modal>
      </div>
    </section>
  )
}

const Row = ({ row, handleStatus, handleCertificateClick, refetch, setApproved }) => {
  const createdDate = new Date(row?.is_created).toLocaleDateString()
  const [select, setSelect] = useState(row?.approval_status || '')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { mutate: prostEngineerApprovalStatus } = useMutation(postEngineerApprovalStatusFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success('Status Updated')
        refetch()
        setLoading(false)
      }
    },
    onError: () => {
      toast.error('Failed to update status')
      setLoading(false)
    },
  })

  const handleChange = (e) => {
    const newStatus = e.target.value
    if (newStatus === 'Rejected') {
      setIsModalOpen(true)
    } else if (newStatus === 'Pending') {
      setSelect(newStatus)
      const formData = new FormData()
      formData.append('status', newStatus)
      formData.append('engineer_id', row.id)
      setLoading(true)
      prostEngineerApprovalStatus(formData)
    } else {
      setApproved(row)
    }
  }

  const handleRemarkSubmit = (remark) => {
    setLoading(true)
    setSelect('Rejected')
    const formData = new FormData()
    formData.append('status', 'Rejected')
    formData.append('engineer_id', row.id)
    formData.append('remark', remark)
    prostEngineerApprovalStatus(formData)
    setIsModalOpen(false)
  }

  // Function to open the modal
  const handleOpen = () => {
    setOpen(true)
  }
  //   const handleOpen = () => setOpen(true);

  // Function to close the modal

  const [profileData, setProfileData] = useState()
  const Update = async (id) => {
   

    const data = await getEngineersByIdFn(id)

    setProfileData(data?.data?.data)

    
  }
  const [open, setOpen] = useState(false)
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false)

  const UpdateProfile = async (formData) => {
    const data = await UpdateProfileFn(formData)

    if (data?.data?.response_code === 200) {
      toast.success(data?.data?.message)
    } else {
      toast.error(data?.data?.message)
    }
  }

  const initialValues = {
    id: profileData?.id || '',
    prefix: profileData?.prefix || '',
    name: profileData?.name || '',
    about: profileData?.about || '',
    dob: profileData?.dob || '',
    gender: profileData?.gender || '',
    fathername: profileData?.fathername || '',
    address: profileData?.address || '',
    country: profileData?.country || 'India',
    state: profileData?.state || 'Uttar Pradesh',
    district: profileData?.district || '',
    block: profileData?.block || '',
    city: profileData?.city || '',
    mobile: profileData?.mobile || '',
    email: profileData?.email || '',
    image: profileData?.image || '',
    // designation:isDesignationEnabled ?( profileData?.designation || "Other"):"",

    member_id: profileData?.member_id || '',
    engineer_id: profileData?.id || '',
  }

  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange: handleChange2,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: ProfileSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
     
      const formData = new FormData()
      Object.keys(values).forEach((key) => formData.append(key, values[key]))

      if (!values.image) return toast.error('Image is required.')

      if (typeof values.image === 'string') {
        formData.delete('image')
      }

      console.log(formData)

      UpdateProfile(formData)
    },
  })

  const DeleteProfile = async (ids) => {
    const data = await DeleteProfileFn(ids)

    if (data?.data?.response_code === 200) {
      toast.success(data?.data?.message)
    } else {
      toast.error(data?.data?.message)
    }
  }

  return (
    <>
      <TableRow key={row?.id} className="!text-slate-700">
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.member_id || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {!row?.image && '--'}
          {row?.image && (
            <img
              src={imageFormat(row?.image)}
              alt={row?.name}
              className="cursor-pointer"
              onClick={() => handleCertificateClick(imageFormat(row?.image))}
            />
          )}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.prefix || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.name || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.fathername || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.address || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.email || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.mobile || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {row?.dob || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {row?.gender || '--'}
        </TableCell>
        {/* <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.country || "--"}
            </TableCell> */}
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {row?.state || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {row?.district || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {row?.block || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          <Switch
            checked={row?.is_activate}
            onChange={() => handleStatus(row.id)}
            color="primary"
          />
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {loading ? (
            <span>Processing...</span>
          ) : row?.approval_status !== 'Approved' ? (
            <select
              className="border border-black outline-none rounded-md w-[100px] py-2"
              value={select}
              onChange={handleChange}
            >
              <option value={'Pending'}>Pending</option>
              <option value={'Approved'}>Approved</option>
              <option value={'Rejected'}>Rejected</option>
            </select>
          ) : (
            <span className="font-bold text-green-700">Approved</span>
          )}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {row?.buy_certificate?.[0]?.status || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          {createdDate || '--'}
        </TableCell>
        <TableCell
          className="!text-center !text-[12px]"
          style={{ borderRight: '1px solid #D0D0D0', fontFamily: "'Poppins', sans-serif" }}
        >
          {row?.certificate ? (
            <Button
              variant="contained"
              className="text-white bg-blue-800 cursor-pointer"
              onClick={() => handleCertificateClick(imageFormat(row?.certificate))}
            >
              Open
            </Button>
          ) : (
            '--'
          )}
        </TableCell>

        <TableCell
          className="!text-center !text-[12px] ! "
          style={{
            borderRight: '1px solid #D0D0D0',
            fontFamily: "'Poppins', sans-serif",
            padding: '1rem',
          }}
        >
          <div className="flex !flex-col !gap-2">
            <Edit
              onClick={() => {
                handleOpen() // Open the modal
                Update(row?.id) // Call the Update function with the member_id
              }}
              className="!text-blue-500"
            />

            <Delete
              onClick={() => {
                DeleteProfile(row?.id)
              }}
              className="!text-red-500"
            />
          </div>
        </TableCell>

        <RemarkModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleRemarkSubmit}
        />
      </TableRow>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="!rounded-xl">
            <form
              class="mt-8 max-w-[1000px] grid  !grid-cols-3  gap-4 mx-auto"
              onSubmit={handleSubmit}
            >
              <FormControl fullWidth error={touched.prefix && Boolean(errors.prefix)}>
                <InputLabel id="prefix-label">I am</InputLabel>
                <Select
                  labelId="prefix-label"
                  id="prefix"
                  name="prefix"
                  value={values.prefix}
                  onChange={handleChange2}
                  onBlur={handleBlur}
                  label="I am"
                >
                  <MenuItem value="ER">Engineer</MenuItem>
                  <MenuItem value="AR">Architect</MenuItem>
                </Select>
                {touched.prefix && errors.prefix && (
                  <FormHelperText>{errors.prefix}</FormHelperText>
                )}
              </FormControl>
              <TextField
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                id="about"
                name="about"
                label="About"
                value={values.about}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                error={touched.about && Boolean(errors.about)}
                helperText={touched.about && errors.about}
              />
              <TextField
                id="dob"
                name="dob"
                type="date"
                label="Date of Birth"
                value={values.dob}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={touched.dob && Boolean(errors.dob)}
                helperText={touched.dob && errors.dob}
              />
              <FormControl fullWidth error={touched.gender && Boolean(errors.gender)}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange2}
                  onBlur={handleBlur}
                  label="Gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {touched.gender && errors.gender && (
                  <FormHelperText>{errors.gender}</FormHelperText>
                )}
              </FormControl>
              <TextField
                id="fathername"
                name="fathername"
                label="Father's Name"
                value={values.fathername}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                error={touched.fathername && Boolean(errors.fathername)}
                helperText={touched.fathername && errors.fathername}
              />
              <TextField
                id="address"
                name="address"
                label="Address"
                value={values.address}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />
              {/* <Autocomplete
                    id="country"
                    name="country"
                    options={country}
                    disabled
                    value={values.country || ""}
                    onChange={(_e, newValue) => setFieldValue("country", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Country"
                            error={touched.country && Boolean(errors.country)}
                            helperText={touched.country && errors.country}
                        />
                    )}
                    onBlur={handleBlur}
                /> */}
              {/* <Autocomplete
                    id="state"
                    name="state"
                    options={states}
                    // disabled
                    value={values.state || ""}
                    onChange={(_e, newValue) => setFieldValue("state", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="State"
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}
                        />
                    )}
                    onBlur={handleBlur}
                /> */}
              {/* <Autocomplete
                    id="designation"
                    name="designation"
                    options={designations}
                    value={values.designation || ""}
                    onChange={(_e, newValue) => setFieldValue("designation", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Designation"
                            error={touched.designation && Boolean(errors.designation)}
                            helperText={touched.designation && errors.designation}
                        />
                    )}
                    onBlur={handleBlur}
                /> */}
{/* 
              <div> */}
                {/* Switch to enable/disable the designation field */}
                {/* <FormControlLabel

             className='!text-xs'
                control={
                    <Switch
                        checked={isDesignationEnabled}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                }
                label="Do you Hold Any Designation ?"
            /> */}

                {/* Conditionally render the Autocomplete field based on switch */}
              {/* </div> */}

              {/* {isDesignationEnabled && (
                <Autocomplete
                    id="designation"
                    name="designation"
                    options={designations}
                    value={values.designation || ""}
                    onChange={(_e, newValue) => setFieldValue("designation", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Designation"
                            error={touched.designation && Boolean(errors.designation)}
                            helperText={touched.designation && errors.designation}
                        />
                    )}
                    onBlur={handleBlur}
                />
            )} */}
              {/* <Autocomplete
                    id="district"
                    name="district"
                    options={cities}
                    value={values.district || ""}
                    onChange={(_e, newValue) => setFieldValue("district", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="District"
                            error={touched.district && Boolean(errors.district)}
                            helperText={touched.district && errors.district}
                        />
                    )}
                    onBlur={handleBlur}
                /> */}

              <TextField
                id="block"
                name="block"
                label="Block"
                value={values.block}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                error={touched.block && Boolean(errors.block)}
                helperText={touched.block && errors.block}
              />
              {/* <Autocomplete
                    id="city"
                    name="city"
                    options={city}
                    value={values.city || ""}
                    onChange={(_e, newValue) => setFieldValue("city", newValue?.label || "")}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Mandal"
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}
                        />
                    )}
                    onBlur={handleBlur}
                /> */}
              <TextField
                id="mobile"
                name="mobile"
                label="Mobile Number"
                value={values.mobile}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                error={touched.mobile && Boolean(errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange2}
                onBlur={handleBlur}
                fullWidth
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              {/* <TextField
                    id="member_id"
                    name="member_id"
                    label="Member ID"
                    value={values.member_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled
                    error={touched.member_id && Boolean(errors.member_id)}
                    helperText={touched.member_id && errors.member_id}
                /> */}
              <div className="col-span-3">
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default Engineers
