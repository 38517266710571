import axios from 'axios';
import { baseURL, publicAxiosInstance } from 'Configs/axios';
import React, { useEffect, useState } from 'react';

const Gallery = () => {
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [scale, setScale] = useState(1);

  const galleryFn = async () => {
    const response = await publicAxiosInstance.get('/blog/gallery-image-api/');
    setData(response.data.data);
  };

  useEffect(() => {
    galleryFn();
  }, []);

  const urlFormat = (url) => {
    return `${baseURL + url}`;
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setScale(1);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleWheel = (event) => {
    event.preventDefault();
    const delta = event.deltaY < 0 ? 1.2 : 0.8;
    setScale(prevScale => Math.max(1, prevScale * delta));
  };

  const handleImageClickZoom = () => {
    setScale(prevScale => prevScale * 1.2);
  };

  const handleImageDoubleClick = () => {
    setScale(1);
  };

  return (
    <div className="flex flex-col items-center justify-center bg-white">

      {selectedImage && (
        <div className="relative mb-4" onWheel={handleWheel}>
          <img
            src={selectedImage}
            alt=""
            style={{ transform: `scale(${scale})`, transition: 'transform 0.3s ease' }}
            className="object-contain max-w-full cursor-pointer max-h-80"
            onClick={handleImageClickZoom}
            onDoubleClick={handleImageDoubleClick}
          />
          <button
            className="absolute text-white top-2 right-2"
            onClick={handleClose}
          >
            ❌
          </button>
        </div>
      )}


      <div className="container grid grid-cols-1 gap-4 pb-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
        {data.map((detail) => (
          <div
            className="relative overflow-hidden text-black transition-transform duration-700 transform border rounded-lg shadow-lg cursor-pointer hover:scale-105 h-80"
            key={detail.id}
            onClick={() => handleImageClick(urlFormat(detail.image))}
          >
            <img
              src={urlFormat(detail.image)}
              alt=""
              className="w-full h-full lg:object-center"
            />
            <p className='absolute bottom-0 w-full py-2 text-center bg-white'>{detail.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
