import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getEngineersByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.getEngineerbyid}?engineer_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const UpdateProfileFn = (fd,idss) => {


  
    try {
        const response = privateAxiosIntance.patch(`${protectedRoutes.getEngineerbyid}?engineer_id=${idss}`,fd);
        return response;
    } catch (error) {
        throw error;
    }
}

export const DeleteProfileFn = (ids) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.getEngineerbyid}`,{data:{engineer_id:ids}});
        return response;
    } catch (error) {
        throw error;
    }
}