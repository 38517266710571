import { Flag } from '@mui/icons-material'
import { publicAxiosInstance } from 'Configs/axios'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

const OurHistory = () => {
  const [data, setData] = useState([])

  const fetchContactData = async () => {
    try {
      const response = await publicAxiosInstance.post(
        '/blog/contact-us-api/'
      )
      setData(response.data.data)
    } catch (error) {
      console.error('Error fetching contact data:', error)
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await publicAxiosInstance.post('/blog/contact-us-api/', values)
      // Handle successful submission (e.g., show a success message)
      console.log('Form submitted successfully:', values)
      // Fetch the contact data after successful submission
      fetchContactData()
      // Reset the form fields
      resetForm()
    } catch (error) {
      console.error('Error submitting form:', error)
      // Handle error (e.g., show an error message)
    }
  }

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email Address is required'),
    mobile: Yup.number().required('Mobile Number is required').positive().integer(),
    message: Yup.string().required('Message is required'),
  })

  return (
    <div>
      <div className="relative flex items-center justify-center">
        <img
          src="https://www.sresindia.org/images/factsbg.jpg"
          alt=""
          className="w-[100%] object-cover h-[1320px] md:h-[1200px] lg:h-[760px]"
        />
        <div className="absolute top-10">
          <div className="flex items-center justify-center">
            <div className="flex lg:flex-row flex-col items-center lg:w-[70%] gap-10 bg-opacity-50 rounded p-4">
              <div className="flex flex-col lg:w-[70%] w-[100%] gap-3">
                <p className="text-4xl font-bold text-white">Our History</p>
                <p className="text-sm text-white lg:text-lg md:text-base">
                  Ceawaup was founded with a vision to provide essential engineering services to rural and village areas, addressing the unique challenges faced by these communities. Our journey began with a small team of dedicated engineers who recognized the need for sustainable infrastructure, clean water, renewable energy solutions, and technological advancements in regions often overlooked.
                </p>
                <p className="text-sm text-white lg:text-lg md:text-base">
                  Over the years, Ceawaup has grown into a trusted organization, committed to empowering village areas with innovative and affordable engineering solutions. Through partnerships with local governments, community leaders, and global agencies, we've successfully implemented projects that have improved living standards, enhanced resource management, and fostered economic growth in these regions.
                </p>
                <p className="text-sm text-white lg:text-lg md:text-base">
                  Our mission remains focused on bridging the gap between urban and rural development, with a deep commitment to sustainable progress. At Ceawaup, we believe that every village deserves access to modern engineering expertise, and we strive to make a lasting impact through our work.
                </p>
                <div className="grid grid-cols-2 gap-5 lg:grid-cols-3 ">
                  <div
                    className="flex items-center justify-center py-4 text-center bg-white"
                  >
                    <Flag className="w-16 text-gray-500 h-14" fontSize="medium" />
                    <div className="flex flex-col">
                      <p className="text-[#d94148] text-3xl font-bold">2023</p>
                      <p className="text-gray-500">Established</p>
                    </div>
                  </div>
                  <div
                    className="flex items-center justify-center py-4 text-center bg-white"
                  >
                    <Flag className="w-16 text-gray-500 h-14" fontSize="medium" />
                    <div className="flex flex-col">
                      <p className="text-[#d94148] text-3xl font-bold">1875</p>
                      <p className="text-gray-500">Engineer & Architect</p>
                    </div>
                  </div>
                  {/* {Array(9)
                    .fill()
                    .map((_, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center p-5 bg-white"
                      >
                        <Flag className="w-16 text-gray-500 h-14" fontSize="medium" />
                        <div className="flex flex-col">
                          <p className="text-[#d94148] text-3xl font-bold">1919</p>
                          <p className="text-gray-500">Established</p>
                        </div>
                      </div>
                    ))} */}
                </div>
              </div>
              <div className="flex flex-col md:w-[70%] lg:w-[40%] w-[90%] md:p-4 p-2 bg-[#d94148] rounded-xl">
                <p className="pt-2 text-lg font-bold text-center text-white md:pt-4 md:text-3xl">Raise Your Issues</p>
                <Formik
                  initialValues={{ first_name: '', email: '', mobile: '', message: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form className="flex flex-col p-4 md:p-8">
                      <div className="flex flex-col !gap-5">
                        <div className="flex flex-col items-center gap-2">
                          <Field
                            className="p-3 border-2 border-black w-[90%] text-black"
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder="Full Name"
                          />
                          {errors.first_name && touched.first_name && (
                            <div className="text-white">{errors.first_name}</div>
                          )}
                        </div>
                        <div className="flex flex-col items-center gap-2">
                          <Field
                            className="p-3 border-2 border-black w-[90%] text-black"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email Address"
                          />
                          {errors.email && touched.email && (
                            <div className="text-white">{errors.email}</div>
                          )}
                        </div>
                        <div className="flex flex-col items-center gap-2">
                          <Field
                            className="p-3 border-2 border-black w-[90%] text-black"
                            id="mobile"
                            name="mobile"
                            type="number"
                            placeholder="Mobile Number"
                          />
                          {errors.mobile && touched.mobile && (
                            <div className="text-white">{errors.mobile}</div>
                          )}
                        </div>
                        <div className="flex flex-col items-center gap-2">
                          <Field
                            className="p-3 border-2 border-black w-[90%] text-black"
                            id="message"
                            name="message"
                            as="textarea"
                            placeholder="Message"
                          />
                          {errors.message && touched.message && (
                            <div className="text-white">{errors.message}</div>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="bg-blue-950 text-white md:mt-5 mt-3 mx-auto p-3 w-[90%] text-xl font-bold"
                      >
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurHistory
