//  export const RAZORPAY_KEY = "rzp_test_sww6eyRpjsDmO2" 
export const RAZORPAY_KEY = "rzp_live_Hw5b76BfR6Q8A4"

export const API_URLS = {
    sendOTP: "/blog/get-otp-api/",
    verifyOTP: "/blog/verify-user-otp-api/",
    country_list_api: "/blog/country-api/",
    state_list_api: "/blog/state-api/",
    city_list_api: "/blog/city-api/",
    new_api: "/blog/news-api/",
    event_api: "/blog/event-api/",
    give_donation: "/blog/give-donation-api/",
    payment_success: "/blog/donation-payment-success-api/",
    certificate_plan: "/blog/certificate-plan-api/",
    get_ceritificate: "/blog/buy-certificate-api/",
    get_ceritificate_payment_success: "/blog/buy-certificate-payment-success-api/",
    engineers_with_designation:"/blog/engineers-with-designation/"
}

export const protectedRoutes = {
    profile: "/blog/engineer-profile-api/",
    paymentHistory: "/blog/engineer-payment-history-api/",
    category_api: "/blog/blog-category-api/",
    sub_category_api: "/blog/blog-subcategory-api/",
    blog: "/blog/blog-api/",
    news: "/blog/news-api/",
    gallery: "/blog/gallery-api/",
    gallery_image: "/blog/gallery-image-api/",
    slider: "/blog/slider-api/",
    banner: "/blog/banner-api/",
    dashboard: "/blog/dashboard-api-of-admin/",
    enginneer: "/blog/engineer-list-api/",
    engineer_status: "/blog/engineer-activate-deactivate-api/",
    engineer_approval: "/blog/engineer-approval-api/",
    create_payment: "/blog/create-payment-api/",
    contact_us: "/blog/contact-us-list-admin-api/",
    event: "/blog/event-api/",
    status_plan_certificate: "/blog/certificate-plan-activate-deactivate-api/",
    donation_list: "/blog/donation-list-api/",
    buy_certificate: "/blog/buy-certificate-api/",
    buy_cerificate_payment_success: "/blog/buy-certificate-payment-success-api/",
    our_members: "/blog/our-members-plan-api/",
    getEngineerbyid:"/blog/engineer-list-api/",
   


}
